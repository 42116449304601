import React from "react"
import loadable from '@loadable/component'
import { HeaderType } from "../types"

const EmptyHeader = loadable(() => import("./headers/empty"))
const DefaultHeader = loadable(() => import("./headers/default"))
const DefaultRelativeHeader = loadable(() => import("./headers/default-relative"))
const PassthroughHeader = loadable(() => import("./headers/passthrough"))
const PassthroughLeftLogoHeader = loadable(() => import("./headers/passthrough-left-logo"))
const PhoneBasicHeader = loadable(() => import("./headers/phone-basic"))

type LayoutProps = {
  header: HeaderType
  [key: string]: any 
}

const Header = ({ header, ...props }: LayoutProps) => {
  switch (header?.templateId || ``) {
    case `empty`:
      return <EmptyHeader header={header} {...props} />
    case `phone-basic`:
      return <PhoneBasicHeader header={header} {...props} />
    case `default-relative`:
      return <DefaultRelativeHeader header={header} {...props} />
    case `passthrough`:
      return <PassthroughHeader header={header} {...props} />
    case `passthrough-left`:
      return <PassthroughLeftLogoHeader header={header} {...props} />
    default:
      return <DefaultHeader header={header} {...props} />
  }
}
  
export default Header
