import React from "react"
import { LinkType } from "../../../types"
import Icon from "../../icons";
import { createMarkup } from "../../../../utils/markup-creator"

type ExternalLinksProps = {
  links: [LinkType]
  [key: string]: any
}

const HeaderExternalLinks = ({ links, ...props }: ExternalLinksProps) => (
  <React.Fragment>
    {links &&
      links.map((link) => (
        <a
          key={link.url}
          href={link.url}
          className={`${link.class || `border border-primary text-primary rounded-full`} self-center px-2 md:px-4 py-1 md:py-2 text-xs md:text-sm font-bold truncate`}
        >
          {link.icon && <span className="pr-1 hidden md:inline"><Icon icon={link.icon} size={15} /></span>}<span dangerouslySetInnerHTML={createMarkup(link.name)}></span>
        </a>
      ))}
  </React.Fragment>
)

export default HeaderExternalLinks
