import React from "react"
import loadable from '@loadable/component'
import { FormType } from "../types"

const ZipForm = loadable(() => import("./forms/zip-form"))
const LeadForm = loadable(() => import("./forms/lead-form"))
const LeadFormv2 = loadable(() => import("./forms/lead-form-v2"))
const QuoteTool = loadable(() => import("./forms/quote-tool"))
const TestimonialVideoForm = loadable(() => import("./forms/testimonial-video"))
const UhsmMemberIntake = loadable(() => import("./forms/uhsm-member-intake"))

type LayoutProps = {
  form: FormType
  [key: string]: any
}

const Form = ({ form, ...props }: LayoutProps) => {
  if (!form?.templateId) {
    return <div>Please add a form template id.</div>
  }

  switch (form.templateId) {
    case `zip-form`:
      return <ZipForm form={form} {...props} />
    case `lead-form`:
      return <LeadForm form={form} {...props} />
    case `lead-form-v2`:
      return <LeadFormv2 form={form} {...props} />
    case `quote-tool`:
      return <QuoteTool form={form} {...props} />
    case `testimonial-video`:
      return <TestimonialVideoForm form={form} {...props} />
    case `uhsm-member-intake`:
      return <UhsmMemberIntake form={form} {...props} />
    default:
      return <ZipForm form={form} {...props} />
  }
}

export default Form
