import { graphql, useStaticQuery } from "gatsby"

type UseMinimalConfigProps = {
  minimalConfig: {
    basePath: string
    pagesPath: string
    headerType: string
    externalLinks: {
      name: string
      url: string
    }[]
    navigation: {
      title: string
      slug: string
    }[]
    footerNavigation: {
      title: string
      slug: string
    }[]
    socialMediaLinks: {
      url: string
      icon: string
    }[]
  }
}

const useMinimalConfig = () => {
  // Guard against an environment where graphql is not accessible
  try {
    const data = useStaticQuery<UseMinimalConfigProps>(graphql`
      query {
        minimalConfig {
          basePath
          pagesPath
          externalLinks {
            name
            url
          }
          navigation {
            title
            slug
          }
          footerNavigation {
            title
            slug
          }
          socialMediaLinks {
            url
            icon
          }
        }
      }
    `)

    return data.minimalConfig
  } catch (ex) {
    if (ex.message.includes(`The result of this StaticQuery could not be fetched`)) {
      return {
        basePath: `basePath`,
        pagesPath: `pagesPath`,
        externalLinks: [],
        navigation: [],
        footerNavigation: [],
        socialMediaLinks: [],
      }
    }

    throw ex
  }
}

export default useMinimalConfig
