import React from "react"
import { Link } from "gatsby"
import useMinimalConfig from "../../../hooks/use-minimal-config"
import replaceSlashes from "../../../../utils/replace-slashes"

type NavigationProps = {
  nav: {
    title: string
    slug: string
  }[]
}

const Navigation = ({ nav }: NavigationProps) => {
  const { basePath } = useMinimalConfig()

  return (
    <React.Fragment>
      {nav && nav.length > 0 && (
        <nav>
          {nav.map((item) => (
            <Link key={item.slug} activeClassName="active" to={replaceSlashes(`/${basePath}/${item.slug}`)}>
              {item.title}
            </Link>
          ))}
        </nav>
      )}
    </React.Fragment>
  )
}

export default Navigation
